import * as THREE from "three";
import type { TColorMap } from "../../types/GlobeTypes";

export const availableColormaps = {
  inferno: 0,
  magma: 1,
  plasma: 2,
  viridis: 3,
  turbo: 4,
  hsluv: 5,
  hpluv: 6,
  cividis: 7,
  Greys: 8,
  Purples: 9,
  Blues: 10,
  Greens: 11,
  Oranges: 12,
  Reds: 13,
  YlOrBr: 14,
  YlOrRd: 15,
  OrRd: 16,
  PuRd: 17,
  RdPu: 18,
  BuPu: 19,
  GnBu: 20,
  PuBu: 21,
  YlGnBu: 22,
  PuBuGn: 23,
  BuGn: 24,
  YlGn: 25,
  PiYG: 26,
  PRGn: 27,
  BrBG: 28,
  PuOr: 29,
  RdGy: 30,
  RdBu: 31,
  RdYlBu: 32,
  RdYlGn: 33,
  Spectral: 34,
  coolwarm: 35,
  bwr: 36,
  seismic: 37,
  thermal: 38,
  haline: 39,
  solar: 40,
  ice: 41,
  gray: 42,
  deep: 43,
  dense: 44,
  algae: 45,
  matter: 46,
  turbid: 47,
  speed: 48,
  amp: 49,
  tempo: 50,
  rain: 51,
  phase: 52,
  balance: 53,
  delta: 54,
  curl: 55,
  diff: 56,
  tarn: 57,
} as const;

// credits: https://www.shadertoy.com/view/3lBXR3
//          https://github.com/mzucker/fit_colormaps
const colormapFragmentShader = `
vec3 inferno(float t) {

    const vec3 coeffs0 = vec3(0.0002135429892708984, 0.001634827339221268, -0.0371299084800798);
    const vec3 coeffs1 = vec3(0.1062891465039799, 0.5685855024567565, 4.134074690966631);
    const vec3 coeffs2 = vec3(11.70840793434026, -3.978746310566526, -16.38508124898758);
    const vec3 coeffs3 = vec3(-42.20190083561337, 17.66391566034188, 45.17241584686221);
    const vec3 coeffs4 = vec3(78.37490472015106, -33.94293757019178, -83.5517902460148);
    const vec3 coeffs5 = vec3(-72.69647029687968, 33.19721747780265, 75.04239771254919);
    const vec3 coeffs6 = vec3(25.68885267596671, -12.51256933516393, -23.66490834455865);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 magma(float t) {

    const vec3 coeffs0 = vec3(-0.002066645282678081, -0.0006875654952920307, -0.009548250714490336);
    const vec3 coeffs1 = vec3(0.25146874454225, 0.6971783863446226, 2.505072352765411);
    const vec3 coeffs2 = vec3(8.41148730177421, -3.624290839499093, 0.3316429685995818);
    const vec3 coeffs3 = vec3(-27.99374136225131, 14.42220394916997, -13.80776161878381);
    const vec3 coeffs4 = vec3(52.99387390661668, -28.38551603032709, 13.08433863532432);
    const vec3 coeffs5 = vec3(-51.76167510049225, 29.6280499735203, 4.354319205352322);
    const vec3 coeffs6 = vec3(19.10773977324762, -11.76304460463165, -5.703137685480804);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 plasma(float t) {

    const vec3 coeffs0 = vec3(0.06405282857545835, 0.02481197740179475, 0.5348995895643989);
    const vec3 coeffs1 = vec3(2.150839989014924, 0.2457088120842075, 0.7458797645993952);
    const vec3 coeffs2 = vec3(-2.674105914011536, -7.519734550167379, 3.13280960692544);
    const vec3 coeffs3 = vec3(6.166695006955908, 42.8081287302393, -28.82830545880596);
    const vec3 coeffs4 = vec3(-11.23969992063919, -83.94875148966631, 61.04178782800466);
    const vec3 coeffs5 = vec3(10.17176638721714, 72.81952981822673, -55.08812967922147);
    const vec3 coeffs6 = vec3(-3.709929468106214, -23.45888148241806, 18.62567963750381);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 viridis(float t) {

    const vec3 coeffs0 = vec3(0.2744554244543788, 0.005767962396576953, 0.3326638811129747);
    const vec3 coeffs1 = vec3(0.1081307117989419, 1.401946035629347, 1.392208914007028);
    const vec3 coeffs2 = vec3(-0.3298127261679012, 0.2165016803674134, 0.09269968253775912);
    const vec3 coeffs3 = vec3(-4.65426085994067, -5.826248177949408, -19.5196626209089);
    const vec3 coeffs4 = vec3(6.301623336010309, 14.37729736226802, 57.55026731536323);
    const vec3 coeffs5 = vec3(4.845692815277936, -14.02115904895309, -66.61185610579274);
    const vec3 coeffs6 = vec3(-5.561150397138682, 4.751861093766102, 26.89636708739268);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 turbo(float t) {

    const vec3 coeffs0 = vec3(0.08054450499145638, 0.06939256448782549, 0.2196219009259153);
    const vec3 coeffs1 = vec3(7.036466295348965, 3.159954354391089, 7.685940753428112);
    const vec3 coeffs2 = vec3(-67.25168408290131, -4.966524176249912, -10.2428461085614);
    const vec3 coeffs3 = vec3(231.3609368682078, 25.39774211407497, -92.76350959281021);
    const vec3 coeffs4 = vec3(-340.1246458513509, -70.38967618900018, 293.2641764119923);
    const vec3 coeffs5 = vec3(224.7801470055489, 68.84500735487303, -311.4221040479775);
    const vec3 coeffs6 = vec3(-55.38092091271513, -22.09144180019314, 113.3662883221752);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 hsluv(float t) {

    const vec3 coeffs0 = vec3(1.054456302736203, 0.2882033558136072, 0.507038010021319);
    const vec3 coeffs1 = vec3(-2.713820043515989, 1.237571822008007, -8.065025515754778);
    const vec3 coeffs2 = vec3(14.48358272082125, 9.831456715454381, 37.81557799405236);
    const vec3 coeffs3 = vec3(-81.97533717997293, -72.77250675664482, -71.6718655957479);
    const vec3 coeffs4 = vec3(161.7486147440366, 180.5810832354277, 78.08969747176995);
    const vec3 coeffs5 = vec3(-116.9629233770591, -195.3529629891082, -46.6422765502115);
    const vec3 coeffs6 = vec3(25.22977356075803, 76.62387354282288, 10.42034377299074);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 hpluv(float t) {

    const vec3 coeffs0 = vec3(0.8487034840367522, 0.482584875538626, 0.5433845196373189);
    const vec3 coeffs1 = vec3(-4.381211729698885, 0.388487417139505, -1.173788930398317);
    const vec3 coeffs2 = vec3(49.00262744073971, -0.1343542325556266, -7.146615316957427);
    const vec3 coeffs3 = vec3(-239.0829871934431, 2.675328225970354, 52.25158475853646);
    const vec3 coeffs4 = vec3(486.4701073457594, -9.057496428609548, -98.74194404154278);
    const vec3 coeffs5 = vec3(-432.2069974207272, 8.228216728400684, 76.99869461410789);
    const vec3 coeffs6 = vec3(140.1584559035967, -2.092281348612012, -22.19675407114375);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 cividis(float t) {

    const vec3 coeffs0 = vec3(-0.008973442521554631, 0.1367558935859388, 0.2941701870939895);
    const vec3 coeffs1 = vec3(-0.3861976307964207, 0.6420015208602643, 2.99435060787088);
    const vec3 coeffs2 = vec3(15.55046104424308, 0.3885915931021348, -22.53950633994509);
    const vec3 coeffs3 = vec3(-59.67360341721702, -1.420781954357377, 75.74776730336835);
    const vec3 coeffs4 = vec3(103.9857738122043, 2.641953572255526, -123.2171811548677);
    const vec3 coeffs5 = vec3(-84.83120467727109, -2.183056400321274, 95.83135688881585);
    const vec3 coeffs6 = vec3(26.38854313061698, 0.7044729971719943, -28.93408760066552);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 Greys(float t) {

    const vec3 coeffs0 = vec3(1.006600859055243, 1.006600859055243, 1.006600859055243);
    const vec3 coeffs1 = vec3(-0.8058888495553654, -0.8058888495553654, -0.8058888495553654);
    const vec3 coeffs2 = vec3(4.151329726731197, 4.151329726731197, 4.151329726731197);
    const vec3 coeffs3 = vec3(-22.3736402951882, -22.3736402951882, -22.3736402951882);
    const vec3 coeffs4 = vec3(44.37539235910495, 44.37539235910495, 44.37539235910495);
    const vec3 coeffs5 = vec3(-39.44345818246951, -39.44345818246951, -39.44345818246951);
    const vec3 coeffs6 = vec3(13.08986203557426, 13.08986203557426, 13.08986203557426);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 Purples(float t) {

    const vec3 coeffs0 = vec3(0.9878520246034748, 0.9830524643038907, 0.9925158330550816);
    const vec3 coeffs1 = vec3(-0.3986678692210497, -0.4448475907567355, -0.3128949944969266);
    const vec3 coeffs2 = vec3(0.520597440153601, 1.122023848782122, 1.26011998251404);
    const vec3 coeffs3 = vec3(-7.923650664655836, -10.69724649965645, -8.088276631470436);
    const vec3 coeffs4 = vec3(17.03700535520993, 24.32300813001797, 16.93373487061859);
    const vec3 coeffs5 = vec3(-14.18239247937508, -24.01158631269822, -15.82959129495987);
    const vec3 coeffs6 = vec3(4.202940890620959, 8.733039797454703, 5.539625499895027);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 Blues(float t) {

    const vec3 coeffs0 = vec3(0.9786010101212481, 0.9875444398085995, 0.9995488674928417);
    const vec3 coeffs1 = vec3(-1.413783133703736, -0.6613965253505383, -0.2551967459427577);
    const vec3 coeffs2 = vec3(8.196156393822546, 1.537863247504115, 0.3908546698678254);
    const vec3 coeffs3 = vec3(-37.00985967929134, -4.714620284418913, -2.794839680508977);
    const vec3 coeffs4 = vec3(66.47062932522077, 4.252799105471284, 4.973946475421295);
    const vec3 coeffs5 = vec3(-53.98400061794709, -0.9317365997486859, -3.342587866202619);
    const vec3 coeffs6 = vec3(16.80709019184263, -0.2875407176076939, 0.4316330565156698);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 Greens(float t) {

    const vec3 coeffs0 = vec3(0.9728328464090119, 0.9850570024221955, 0.9604526348120273);
    const vec3 coeffs1 = vec3(-0.7375336854446003, -0.04061343908822596, -0.6606783902678897);
    const vec3 coeffs2 = vec3(2.142154785862701, -1.963765893861027, 0.9476038454721575);
    const vec3 coeffs3 = vec3(-14.76522727352428, 6.44781200291773, -13.17224593250883);
    const vec3 coeffs4 = vec3(28.2189650573281, -13.59485565806986, 33.86617673716003);
    const vec3 coeffs5 = vec3(-23.38106846434649, 13.11698566443991, -33.76931913807611);
    const vec3 coeffs6 = vec3(7.557306258421494, -4.6935326579414, 11.94137735114815);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 Oranges(float t) {

    const vec3 coeffs0 = vec3(1.000620986925256, 0.9547389842634258, 0.9152813626787504);
    const vec3 coeffs1 = vec3(-0.06663602103266926, -0.185559788257726, -0.5721925034172531);
    const vec3 coeffs2 = vec3(0.5337174145790911, -2.529137182415981, -3.02690195132434);
    const vec3 coeffs3 = vec3(-3.765604367615589, 5.779375097393151, 3.29435885744151);
    const vec3 coeffs4 = vec3(12.97262453131883, -11.42125921702416, -2.94951758199684);
    const vec3 coeffs5 = vec3(-18.94638410506066, 12.25659067061867, 5.950203966421338);
    const vec3 coeffs6 = vec3(8.777538258979009, -4.703008252674679, -3.603134427432886);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 Reds(float t) {

    const vec3 coeffs0 = vec3(0.9907095911267549, 0.9482064601522318, 0.9345183287503398);
    const vec3 coeffs1 = vec3(0.4739217902531088, 0.09646500501745926, -0.510115879487077);
    const vec3 coeffs2 = vec3(-5.986794206494905, -8.878406623219615, -4.644061278305616);
    const vec3 coeffs3 = vec3(26.04065713430781, 32.62217884090335, 9.055962767780912);
    const vec3 coeffs4 = vec3(-49.4037918653793, -64.66760969433001, -7.37368969311336);
    const vec3 coeffs5 = vec3(41.03813980301521, 62.06517445804191, 3.825653034976981);
    const vec3 coeffs6 = vec3(-12.76192392510529, -22.20258146851515, -1.242377120085111);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 YlOrBr(float t) {

    const vec3 coeffs0 = vec3(0.9957923740036836, 0.9999597739627409, 0.8880256182651309);
    const vec3 coeffs1 = vec3(0.2446317944608416, -0.2341287044397828, -1.001981273916024);
    const vec3 coeffs2 = vec3(-2.969748085306223, 0.2381455271366298, -0.1945092934172723);
    const vec3 coeffs3 = vec3(12.46529366345535, -6.624039072484575, -12.67429121432743);
    const vec3 coeffs4 = vec3(-21.3882903754373, 9.930345972699497, 39.17424285866823);
    const vec3 coeffs5 = vec3(13.83753215277221, -4.462470478303552, -40.3727511248369);
    const vec3 coeffs6 = vec3(-2.787798214192021, 0.296135008599569, 14.22205990331003);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 YlOrRd(float t) {

    const vec3 coeffs0 = vec3(0.9967119000994015, 0.9896834759989743, 0.7839064798654998);
    const vec3 coeffs1 = vec3(0.1498412075867762, -0.05975668531621556, -0.5834570127414268);
    const vec3 coeffs2 = vec3(-1.369534186655536, -5.400346151945256, -8.674188749151485);
    const vec3 coeffs3 = vec3(3.52625122205817, 23.00446818549802, 35.24057045737104);
    const vec3 coeffs4 = vec3(-1.369572326852638, -53.55670313608542, -61.99016573732207);
    const vec3 coeffs5 = vec3(-4.182807121522607, 54.07509689843663, 52.46065848416781);
    const vec3 coeffs6 = vec3(2.74463891261981, -19.0503567684697, -17.09233061618885);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 OrRd(float t) {

    const vec3 coeffs0 = vec3(0.9970761558951423, 0.9734930907222625, 0.9269785684256941);
    const vec3 coeffs1 = vec3(0.1448871060551022, -0.7473332700585568, -1.159129712097794);
    const vec3 coeffs2 = vec3(-2.284775387520577, 3.499174345516884, -0.09747249511480879);
    const vec3 coeffs3 = vec3(10.76355744084192, -17.63130165776738, -1.700228936899568);
    const vec3 coeffs4 = vec3(-21.05903644378247, 33.35506455832593, 10.3234595627333);
    const vec3 coeffs5 = vec3(17.13513188852754, -31.29310881543107, -16.07787861523823);
    const vec3 coeffs6 = vec3(-5.211174842805613, 11.87316614879456, 7.810318476453378);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 PuRd(float t) {

    const vec3 coeffs0 = vec3(0.947463187513386, 0.956652985388232, 0.9565978242899993);
    const vec3 coeffs1 = vec3(0.5560982362192739, -0.3591708206331743, 0.8388988012665156);
    const vec3 coeffs2 = vec3(-10.91624231925755, -4.122364293173114, -14.02370103236994);
    const vec3 coeffs3 = vec3(36.51575291892854, 14.3888303411935, 59.63625574140824);
    const vec3 coeffs4 = vec3(-42.28272731717689, -33.83020824051544, -119.83964117225);
    const vec3 coeffs5 = vec3(13.00562006422398, 37.70667363351793, 109.7281056034795);
    const vec3 coeffs6 = vec3(2.598996866306198, -14.75905111297822, -37.19418791691235);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 RdPu(float t) {

    const vec3 coeffs0 = vec3(0.9905094437450921, 0.9631838177607324, 0.945793936046837);
    const vec3 coeffs1 = vec3(0.4453186115507279, -0.4325062456258478, -0.1874809612869344);
    const vec3 coeffs2 = vec3(-6.051403620440308, -3.559570994092572, -7.015509356713514);
    const vec3 coeffs3 = vec3(27.52712234454064, 17.7551151740404, 31.26288597842063);
    const vec3 coeffs4 = vec3(-53.69031871479761, -49.93706923464817, -59.34774745321437);
    const vec3 coeffs5 = vec3(43.90721101526071, 57.96953180509626, 51.42902801614255);
    const vec3 coeffs6 = vec3(-12.84295211529921, -22.76900089356529, -16.67667243960764);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 BuPu(float t) {

    const vec3 coeffs0 = vec3(0.9619963077338282, 0.991877025441436, 0.9963446769518383);
    const vec3 coeffs1 = vec3(-0.3990772452739235, -0.6127822646486986, -0.4426517246501792);
    const vec3 coeffs2 = vec3(-2.628772191804586, 0.7794199884378651, 1.41138930791194);
    const vec3 coeffs3 = vec3(0.982636186521346, -5.36819704316996, -6.585887455617179);
    const vec3 coeffs4 = vec3(11.82954758841245, 10.05739479610352, 11.19438809946659);
    const vec3 coeffs5 = vec3(-15.83422997908528, -10.27980654133462, -8.359770475010063);
    const vec3 coeffs6 = vec3(5.372007692893933, 4.443068667151618, 2.063580460350659);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 GnBu(float t) {

    const vec3 coeffs0 = vec3(0.9785967988623567, 0.9839401219851954, 0.9163436051349385);
    const vec3 coeffs1 = vec3(-1.344148256076547, -0.0945848531885536, 0.5634744009037174);
    const vec3 coeffs2 = vec3(7.960294881513993, -1.877768105952762, -12.77982177380808);
    const vec3 coeffs3 = vec3(-34.65948382344121, 8.144610452257673, 46.63939161542517);
    const vec3 coeffs4 = vec3(61.57736225102193, -17.50592244476741, -68.22266922327485);
    const vec3 coeffs5 = vec3(-50.84355675220153, 15.36507209390368, 43.46389543602062);
    const vec3 coeffs6 = vec3(16.37874898866765, -4.766614499261443, -10.06822567333352);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 PuBu(float t) {

    const vec3 coeffs0 = vec3(0.9886655149126025, 0.9598414521830083, 0.9869607300756792);
    const vec3 coeffs1 = vec3(0.04182661239488721, 0.008805629539528117, -0.3941977679813645);
    const vec3 coeffs2 = vec3(-7.586538666900353, -6.145749490428234, 1.196187166122408);
    const vec3 coeffs3 = vec3(32.74458259297089, 24.90645006092411, -6.640055253395501);
    const vec3 coeffs4 = vec3(-77.7240147679851, -47.76789331936853, 14.807245005795);
    const vec3 coeffs5 = vec3(82.53501158591916, 42.397386889952, -14.44214888855371);
    const vec3 coeffs6 = vec3(-31.00924148876915, -14.15052405300227, 4.816623620902585);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 YlGnBu(float t) {

    const vec3 coeffs0 = vec3(1.008788302189706, 0.9862097528783926, 0.858130919326944);
    const vec3 coeffs1 = vec3(-1.164272777014644, 0.4492565169415262, -1.786122684268387);
    const vec3 coeffs2 = vec3(9.918623944677423, -6.52463771446555, 4.87597237309652);
    const vec3 coeffs3 = vec3(-62.53817010940736, 20.32620266384917, 4.429260850355411);
    const vec3 coeffs4 = vec3(128.4798805680538, -33.22831397084941, -30.09294362108092);
    const vec3 coeffs5 = vec3(-107.5755124687593, 24.17021486876348, 36.0325945892378);
    const vec3 coeffs6 = vec3(31.88294502554296, -6.051248890114845, -13.99599476338677);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 PuBuGn(float t) {

    const vec3 coeffs0 = vec3(0.9964150636234088, 0.9733066487559491, 0.9731385353172217);
    const vec3 coeffs1 = vec3(-0.4277077998398535, -0.9087718480264263, 0.1672595767188661);
    const vec3 coeffs2 = vec3(-1.58814417860028, 2.601927048026092, -5.159043049530896);
    const vec3 coeffs3 = vec3(4.74795646655345, -7.886930392050131, 19.41632640540788);
    const vec3 coeffs4 = vec3(-20.99998499670491, 9.341097031474133, -29.97057860343462);
    const vec3 coeffs5 = vec3(30.69903739575726, -3.314811812354384, 17.02017075421897);
    const vec3 coeffs6 = vec3(-13.4272979020902, -0.5501016228541173, -2.214145553898643);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 BuGn(float t) {

    const vec3 coeffs0 = vec3(0.9758638885421833, 0.9846418672932192, 0.9807459610399274);
    const vec3 coeffs1 = vec3(-1.128357257173429, -0.1127468334720763, 0.5198082555768663);
    const vec3 coeffs2 = vec3(9.087394272723664, -0.2191643077379171, -7.159236426017581);
    const vec3 coeffs3 = vec3(-51.90511728652574, -3.140026232961441, 24.66768228634587);
    const vec3 coeffs4 = vec3(108.691330762002, 7.62325793949394, -49.98921628863653);
    const vec3 coeffs5 = vec3(-99.67292551017427, -7.511904913070151, 47.45578857180138);
    const vec3 coeffs6 = vec3(33.98121632923645, 2.641876244330783, -16.3724419193247);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 YlGn(float t) {

    const vec3 coeffs0 = vec3(1.003509106676874, 0.9913849908401118, 0.9024652396466273);
    const vec3 coeffs1 = vec3(-0.3787450747044345, 0.3604968192656732, -1.681240258235132);
    const vec3 coeffs2 = vec3(2.206036980916254, -4.76308481756973, 2.585332983438358);
    const vec3 coeffs3 = vec3(-21.56963351565939, 15.44904581324503, 3.210386927171164);
    const vec3 coeffs4 = vec3(44.79185704397433, -28.75810093504999, -20.93180705612615);
    const vec3 coeffs5 = vec3(-38.38765935202581, 25.30518925165336, 26.65630157944175);
    const vec3 coeffs6 = vec3(12.34252902488809, -8.318723801515331, -10.58913828877847);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 PiYG(float t) {

    const vec3 coeffs0 = vec3(0.5396102001732856, 0.02073079101629638, 0.2980295335914711);
    const vec3 coeffs1 = vec3(3.375696584346493, -0.8068537101177474, 2.864632952645329);
    const vec3 coeffs2 = vec3(-15.71945516956515, 28.53693114936268, -13.66670710917048);
    const vec3 coeffs3 = vec3(50.21441410279919, -93.36165649305789, 69.87400461915666);
    const vec3 coeffs4 = vec3(-86.25290460323896, 136.7791794692547, -160.7755153128512);
    const vec3 coeffs5 = vec3(66.453313743328, -100.3074234217044, 150.9483265150239);
    const vec3 coeffs6 = vec3(-18.44978574678262, 29.54200511468484, -49.42838298363398);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 PRGn(float t) {

    const vec3 coeffs0 = vec3(0.2353206760297253, -0.003402742465666742, 0.2696625657488418);
    const vec3 coeffs1 = vec3(3.332555791723883, 1.483796895006175, 3.739750907223483);
    const vec3 coeffs2 = vec3(-17.67025789856327, 4.721157789342191, -19.85129363880082);
    const vec3 coeffs3 = vec3(76.76135459984731, -6.62227619790637, 83.19735765927913);
    const vec3 coeffs4 = vec3(-152.6235722305856, -7.207483681910011, -169.0170565854501);
    const vec3 coeffs5 = vec3(127.9249391624417, 11.32570302806576, 149.2758091375414);
    const vec3 coeffs6 = vec3(-37.95218946190842, -3.432749064626403, -47.50937578927697);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 BrBG(float t) {

    const vec3 coeffs0 = vec3(0.3077480132335619, 0.2095271364879333, 0.02893391753943497);
    const vec3 coeffs1 = vec3(3.388177261360926, 0.1394026269952716, -0.08683152269655477);
    const vec3 coeffs2 = vec3(-13.60790629552669, 11.57853951146872, -3.071726160302077);
    const vec3 coeffs3 = vec3(56.34984742182872, -24.23440338730826, 70.84247638684359);
    const vec3 coeffs4 = vec3(-120.5440219937269, 12.47789873173324, -194.2638759320675);
    const vec3 coeffs5 = vec3(106.6507893503822, 2.163942903083186, 192.8145943450473);
    const vec3 coeffs6 = vec3(-32.52292932771905, -2.099689694880882, -66.11229349636028);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 PuOr(float t) {

    const vec3 coeffs0 = vec3(0.4968668025717513, 0.2394919183948807, 0.03085217313461186);
    const vec3 coeffs1 = vec3(2.025654225753624, 0.7970831117509098, 0.5714999852591073);
    const vec3 coeffs2 = vec3(1.645974343215869, 1.470997011285385, -18.35980325793518);
    const vec3 coeffs3 = vec3(-15.52089359464755, 18.18582886104045, 142.9571466186619);
    const vec3 coeffs4 = vec3(21.74324150181477, -64.9448505605413, -334.7991854311516);
    const vec3 coeffs5 = vec3(-14.50239650713024, 65.48857397489898, 318.2310840345058);
    const vec3 coeffs6 = vec3(4.292782384349967, -21.25207528651062, -108.408023198186);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 RdGy(float t) {

    const vec3 coeffs0 = vec3(0.3928992161164051, 0.008264254791302772, 0.1039187593381925);
    const vec3 coeffs1 = vec3(3.943822444236518, 0.1428174065840777, 1.661102838608557);
    const vec3 coeffs2 = vec3(-13.98243251654586, 10.2022293717764, -18.67596906738429);
    const vec3 coeffs3 = vec3(39.38679586585966, -2.29387761747123, 121.0433775752429);
    const vec3 coeffs4 = vec3(-68.93607507736999, -49.65447467925131, -272.4564697099822);
    const vec3 coeffs5 = vec3(56.27918099476251, 67.85290115007406, 251.8563165149819);
    const vec3 coeffs6 = vec3(-16.99539472560624, -26.18001122526902, -83.47713114900712);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 RdBu(float t) {

    const vec3 coeffs0 = vec3(0.3727901174631079, 0.01075966730532318, 0.1059791351690489);
    const vec3 coeffs1 = vec3(4.971292554474112, -0.04166831562863782, 1.501103321104131);
    const vec3 coeffs2 = vec3(-25.79047024742871, 12.81048463658467, -16.47850399780497);
    const vec3 coeffs3 = vec3(91.78453982600422, -15.07661218914964, 111.4831778383526);
    const vec3 coeffs4 = vec3(-173.3657880135999, -23.62494767960913, -258.3695684316585);
    const vec3 coeffs5 = vec3(148.1573174898774, 45.0809585427183, 248.1376633615656);
    const vec3 coeffs6 = vec3(-46.09310035022121, -18.98643145562933, -86.06328787813436);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 RdYlBu(float t) {

    const vec3 coeffs0 = vec3(0.6173027505079096, 0.005388007147405283, 0.1238303600136956);
    const vec3 coeffs1 = vec3(3.63319906471339, 1.603138653713788, 0.9825978559344906);
    const vec3 coeffs2 = vec3(-18.4824496751465, 1.952233504492512, -7.869355782078996);
    const vec3 coeffs3 = vec3(58.79612244188453, 11.87020395017818, 42.31139379790621);
    const vec3 coeffs4 = vec3(-100.3405374893958, -53.08472954593667, -69.48960496267979);
    const vec3 coeffs5 = vec3(77.46438375031866, 59.07803393070449, 41.14682407317893);
    const vec3 coeffs6 = vec3(-21.48009989363574, -21.23768928547428, -6.585694152954783);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 RdYlGn(float t) {

    const vec3 coeffs0 = vec3(0.628929186238068, 0.004637053094083576, 0.1207579418086837);
    const vec3 coeffs1 = vec3(3.040779235404921, 1.690121009291412, 1.657197563214252);
    const vec3 coeffs2 = vec3(-11.70306219218746, 0.3378982650388359, -21.77011200914948);
    const vec3 coeffs3 = vec3(28.60184360556573, 22.05406660897056, 131.5079295863619);
    const vec3 coeffs4 = vec3(-38.31366271913282, -80.1381687860117, -300.6947580601619);
    const vec3 coeffs5 = vec3(18.28428184433216, 89.92129861131023, 290.4924429551797);
    const vec3 coeffs6 = vec3(-0.5218331356744457, -33.49874906873701, -101.1734062672855);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 Spectral(float t) {

    const vec3 coeffs0 = vec3(0.5906911910466978, 0.0107878160116659, 0.2353346396474976);
    const vec3 coeffs1 = vec3(3.723353063269672, 2.479165694446118, 2.711841131208065);
    const vec3 coeffs2 = vec3(-16.11573561793281, -6.170066330834588, -36.5262639695958);
    const vec3 coeffs3 = vec3(39.5792626814546, 39.81435589260795, 184.5750550160909);
    const vec3 coeffs4 = vec3(-45.92939793947738, -99.76498758905674, -387.4226313140066);
    const vec3 coeffs5 = vec3(12.48816436457374, 97.75399229753262, 361.37033472606);
    const vec3 coeffs6 = vec3(6.106056776884729, -33.85940770814738, -124.3972098108639);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 coolwarm(float t) {

    const vec3 coeffs0 = vec3(0.2284927239464558, 0.2890337657614808, 0.7544508730405812);
    const vec3 coeffs1 = vec3(1.209148543573963, 2.308782797929739, 1.565018051709207);
    const vec3 coeffs2 = vec3(0.09440683758290924, -7.335911877741697, -1.889871770155974);
    const vec3 coeffs3 = vec3(2.267155723842107, 32.60337423142011, -1.604197978482374);
    const vec3 coeffs4 = vec3(-5.211327179232611, -75.84718811546922, -3.80065988450061);
    const vec3 coeffs5 = vec3(1.4422071109688, 74.23835765282, 9.761545722360259);
    const vec3 coeffs6 = vec3(0.6730757561376177, -26.24251233104311, -4.645203112066666);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 bwr(float t) {

    const vec3 coeffs0 = vec3(-0.01749614667033228, -0.03499229334066456, 0.9825038533296757);
    const vec3 coeffs1 = vec3(3.089575831441586, 4.17130852562827, 1.081732694186684);
    const vec3 coeffs2 = vec3(-14.41556055492132, -28.83112110984271, -14.4155605549213);
    const vec3 coeffs3 = vec3(71.32098340125901, 142.6419668025181, 71.32098340125894);
    const vec3 coeffs4 = vec3(-152.7092318666328, -305.4184637332659, -152.7092318666327);
    const vec3 coeffs5 = vec3(140.8408336269687, 281.6816672539375, 140.8408336269686);
    const vec3 coeffs6 = vec3(-47.13105020719496, -94.26210041438998, -47.13105020719493);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 seismic(float t) {

    const vec3 coeffs0 = vec3(-0.03264980267110928, -0.1173642238457191, 0.2418217077959568);
    const vec3 coeffs1 = vec3(2.839452455095549, 7.705261993758005, 5.672270632283274);
    const vec3 coeffs2 = vec3(-45.57576018127519, -107.3190142286882, -31.37179803278704);
    const vec3 coeffs3 = vec3(239.6492867013913, 537.397278976374, 141.9494539113747);
    const vec3 coeffs4 = vec3(-478.6840031853826, -1116.683586238167, -328.2510711552419);
    const vec3 coeffs5 = vec3(414.7115590302986, 1020.303030627542, 328.9216582259169);
    const vec3 coeffs6 = vec3(-132.4897351224417, -341.4347396609831, -117.1986812392192);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 thermal(float t) {

    const vec3 coeffs0 = vec3(0.0279113676003373, 0.1432577743791663, 0.1436307083968273);
    const vec3 coeffs1 = vec3(-1.109828498759061, 0.7781182132365964, 4.379367788635243);
    const vec3 coeffs2 = vec3(22.23946974174684, -5.832614597510585, -15.3741377889983);
    const vec3 coeffs3 = vec3(-81.25652948795974, 29.35134645367647, 27.7393157878179);
    const vec3 coeffs4 = vec3(146.0214461025263, -63.90791679517994, -32.66583790515124);
    const vec3 coeffs5 = vec3(-126.7151186125115, 63.51648772117898, 21.05487307757799);
    const vec3 coeffs6 = vec3(41.72433215957059, -23.08278101658951, -4.88632220650828);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 haline(float t) {

    const vec3 coeffs0 = vec3(0.1766212417576263, 0.09070315249063476, 0.3872431632297797);
    const vec3 coeffs1 = vec3(0.6964628514516502, -0.08210434895510861, 5.227838908694082);
    const vec3 coeffs2 = vec3(-18.87557827934277, 12.85306086229499, -39.68135217224653);
    const vec3 coeffs3 = vec3(97.72617729116268, -52.5499392532533, 128.1268383049751);
    const vec3 coeffs4 = vec3(-203.8714509640863, 97.22584295376416, -201.0341377042199);
    const vec3 coeffs5 = vec3(192.2545374222112, -83.97410974182993, 148.5988410895647);
    const vec3 coeffs6 = vec3(-67.15818391639789, 27.38841083951214, -40.98660953177028);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 solar(float t) {

    const vec3 coeffs0 = vec3(0.2042200387514365, 0.07440490059826632, 0.09202546124439426);
    const vec3 coeffs1 = vec3(1.044805366484453, 0.6700878943327605, 0.555204879019461);
    const vec3 coeffs2 = vec3(2.710168612014845, -4.048954609547989, -0.8037668797272495);
    const vec3 coeffs3 = vec3(-11.77303717577573, 18.40169654839414, -7.257996769164512);
    const vec3 coeffs4 = vec3(18.10004534906204, -31.24184393521019, 21.65639403315007);
    const vec3 coeffs5 = vec3(-12.93902124269371, 24.3542729246093, -20.65539366722603);
    const vec3 coeffs6 = vec3(3.534066299641732, -7.212350394154891, 6.711625158670627);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 ice(float t) {

    const vec3 coeffs0 = vec3(0.01758450362034791, 0.01835070803497985, 0.08692961976104757);
    const vec3 coeffs1 = vec3(0.6181468653635807, 1.130011808712653, 0.7449283822632478);
    const vec3 coeffs2 = vec3(5.388658769454588, -3.649414111382249, 6.742385152670783);
    const vec3 coeffs3 = vec3(-30.49012151275036, 14.32490661575189, -18.30937697019652);
    const vec3 coeffs4 = vec3(58.18871389680676, -22.86341178425113, 14.90202653489209);
    const vec3 coeffs5 = vec3(-46.17329677751572, 16.58247567190169, -0.8757393390770166);
    const vec3 coeffs6 = vec3(13.38436774716543, -4.550026895073123, -2.299743434287438);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 gray(float t) {

    const vec3 coeffs0 = vec3(-1.52655665885959e-16, -1.52655665885959e-16, -1.52655665885959e-16);
    const vec3 coeffs1 = vec3(1.003921568627459, 1.003921568627459, 1.003921568627459);
    const vec3 coeffs2 = vec3(-5.99198969626671e-14, -5.99198969626671e-14, -5.99198969626671e-14);
    const vec3 coeffs3 = vec3(1.940543442948159e-13, 1.940543442948159e-13, 1.940543442948159e-13);
    const vec3 coeffs4 = vec3(-3.275753176734781e-13, -3.275753176734781e-13, -3.275753176734781e-13);
    const vec3 coeffs5 = vec3(2.791598243435466e-13, 2.791598243435466e-13, 2.791598243435466e-13);
    const vec3 coeffs6 = vec3(-9.404272972441345e-14, -9.404272972441345e-14, -9.404272972441345e-14);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 deep(float t) {

    const vec3 coeffs0 = vec3(0.9827681266175232, 0.9918738246739465, 0.8224346449479691);
    const vec3 coeffs1 = vec3(-1.257224842304318, -0.7075414193713646, -2.406902696108136);
    const vec3 coeffs2 = vec3(-11.33870685899198, 0.4004117134483695, 14.53826387020545);
    const vec3 coeffs3 = vec3(50.64848534885826, -3.83161737161128, -47.72524504755634);
    const vec3 coeffs4 = vec3(-86.15655742081056, 7.276761514242808, 84.1916771737294);
    const vec3 coeffs5 = vec3(68.57126001084441, -5.968641544723556, -74.24734431239276);
    const vec3 coeffs6 = vec3(-21.31720090578653, 1.946145185182009, 24.99483683213778);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 dense(float t) {

    const vec3 coeffs0 = vec3(0.889641031424008, 0.9455204125616041, 0.9466964213826823);
    const vec3 coeffs1 = vec3(-1.021063619912845, -0.9066003036963616, -0.8014727722699568);
    const vec3 coeffs2 = vec3(-8.573768051657241, 1.128777589295576, 2.953864314624335);
    const vec3 coeffs3 = vec3(41.3978174987901, -6.02016147501676, 0.6095010806825362);
    const vec3 coeffs4 = vec3(-68.51519072933661, 9.384477607834381, -16.9780512454869);
    const vec3 coeffs5 = vec3(49.47946504786862, -6.00498155470931, 20.84790382113688);
    const vec3 coeffs6 = vec3(-13.44894963230739, 1.533106739509069, -7.448436587557593);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 algae(float t) {

    const vec3 coeffs0 = vec3(0.8794786575298623, 0.9766148581045072, 0.803289055403873);
    const vec3 coeffs1 = vec3(-3.327936758323631, -0.8971996718893139, -0.9472678953774726);
    const vec3 coeffs2 = vec3(25.55653884579595, -0.4310168699251268, -4.858449375012033);
    const vec3 coeffs3 = vec3(-117.6900499306417, 5.469945076968334, 17.9794330054104);
    const vec3 coeffs4 = vec3(231.8572765305647, -14.40946506669296, -20.74681199249107);
    const vec3 coeffs5 = vec3(-201.6011286486202, 14.60644535993693, 7.689282237906392);
    const vec3 coeffs6 = vec3(64.40625859577257, -5.179953469114574, 0.1673992026578811);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 matter(float t) {

    const vec3 coeffs0 = vec3(0.9947229002855287, 0.9325055633893744, 0.6855864905175555);
    const vec3 coeffs1 = vec3(-0.1196572146731039, -1.548833595793223, -1.147743219195998);
    const vec3 coeffs2 = vec3(-0.4850448794550009, 1.977326165510778, -1.393377914645434);
    const vec3 coeffs3 = vec3(3.26315869605722, -9.07420533357976, 5.641578757742471);
    const vec3 coeffs4 = vec3(-12.23535945303564, 17.74099774027416, 2.896482426826288);
    const vec3 coeffs5 = vec3(13.7131693149272, -13.21175193950989, -13.52700963867352);
    const vec3 coeffs6 = vec3(-4.953822989074388, 3.240136428546595, 7.091956463662109);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 turbid(float t) {

    const vec3 coeffs0 = vec3(0.9138789562818207, 0.9639183512566035, 0.6666659891046508);
    const vec3 coeffs1 = vec3(-0.6333880181886875, -1.192645060883887, -1.210399511044695);
    const vec3 coeffs2 = vec3(0.963911605564554, 0.5187212200311613, -3.148198003941789);
    const vec3 coeffs3 = vec3(-0.9684166779782667, -2.502754393026077, 16.28293856536935);
    const vec3 coeffs4 = vec3(-3.900585053287812, 7.021181585397692, -23.35425329814415);
    const vec3 coeffs5 = vec3(6.383418464405664, -7.08745153700027, 13.50188401572183);
    const vec3 coeffs6 = vec3(-2.630313521280119, 2.396972379389654, -2.630770083252994);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 speed(float t) {

    const vec3 coeffs0 = vec3(0.9939152199769321, 0.9907144362448561, 0.7894081111964772);
    const vec3 coeffs1 = vec3(-0.3726753642061184, -1.158941049549711, -1.499399356581949);
    const vec3 coeffs2 = vec3(-3.224966859448368, -0.9622418312527263, -5.071720860367504);
    const vec3 coeffs3 = vec3(13.08622087536297, 10.03471688362697, 6.2079639007748);
    const vec3 coeffs4 = vec3(-42.5987225252678, -21.22165097071376, 27.96729659235095);
    const vec3 coeffs5 = vec3(56.97416849372849, 17.81630119127278, -51.53804919610541);
    const vec3 coeffs6 = vec3(-24.79676789395366, -5.365779430269602, 23.24547150156723);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 amp(float t) {

    const vec3 coeffs0 = vec3(0.9437969652965332, 0.9213557287075931, 0.9422640941590248);
    const vec3 coeffs1 = vec3(-0.4995129186938637, -0.7352901929286123, -2.198005707600125);
    const vec3 coeffs2 = vec3(0.6903113356805973, -6.006669046093385, 8.857524728339362);
    const vec3 coeffs3 = vec3(-2.513035116390402, 31.12034077197472, -39.03763740737009);
    const vec3 coeffs4 = vec3(7.402375566333713, -71.20584854249894, 75.19718464156867);
    const vec3 coeffs5 = vec3(-10.96562606858021, 72.57347249297119, -61.87559713241086);
    const vec3 coeffs6 = vec3(5.18228437136206, -26.6474574987131, 18.16641273037705);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 tempo(float t) {

    const vec3 coeffs0 = vec3(1.031013789795004, 0.9621385166440148, 0.9500794173926475);
    const vec3 coeffs1 = vec3(-3.086504481329367, -0.8886237534507524, -1.150553886015295);
    const vec3 coeffs2 = vec3(20.08920551037135, -0.5558513288374533, -3.540458770544508);
    const vec3 coeffs3 = vec3(-90.53390725166102, 6.739016557522408, 17.83073851959257);
    const vec3 coeffs4 = vec3(172.8921358503448, -17.57144536429411, -26.89711024462512);
    const vec3 coeffs5 = vec3(-144.6012371945659, 17.64703899674161, 16.40966481490272);
    const vec3 coeffs6 = vec3(44.28362218241872, -6.22363556546511, -3.333755679312134);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 rain(float t) {

    const vec3 coeffs0 = vec3(0.9477265382869122, 0.9339703799907506, 0.9618483529232216);
    const vec3 coeffs1 = vec3(-1.499417678924503, -1.186953717197214, -1.745470818680112);
    const vec3 coeffs2 = vec3(11.31152763913202, -0.1955286090967123, -2.873157562527315);
    const vec3 coeffs3 = vec3(-54.21397777317982, 9.757521933733903, 19.10691210863348);
    const vec3 coeffs4 = vec3(88.51933687065471, -25.45201281699683, -28.52575559756788);
    const vec3 coeffs5 = vec3(-54.94791625192641, 24.47982371858794, 16.18038787512166);
    const vec3 coeffs6 = vec3(9.963922633648489, -8.238663962071707, -2.88943078851877);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 phase(float t) {

    const vec3 coeffs0 = vec3(0.6434194713561787, 0.4266582589788506, 0.09961641157369072);
    const vec3 coeffs1 = vec3(2.303402074388369, 1.786799100429309, -0.4481552068516109);
    const vec3 coeffs2 = vec3(-12.144686313635, -36.49789676403125, 17.52385147328846);
    const vec3 coeffs3 = vec3(53.17677476182934, 158.4780396451219, -33.8918393284618);
    const vec3 coeffs4 = vec3(-149.3183197984342, -280.6221533669769, 8.629541225302125);
    const vec3 coeffs5 = vec3(176.5761985199324, 225.4663429179796, 16.1348396816081);
    const vec3 coeffs6 = vec3(-70.59270705425052, -68.59944786117804, -8.017001001827168);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 balance(float t) {

    const vec3 coeffs0 = vec3(0.1094124783774468, 0.08451874568982731, 0.1173650134262694);
    const vec3 coeffs1 = vec3(1.948464383897826, 2.78053500731349, 11.061551567864);
    const vec3 coeffs2 = vec3(-36.28073971856319, -18.21011218065756, -85.81341905513787);
    const vec3 coeffs3 = vec3(210.0663282824169, 99.99243976765656, 325.4156211865188);
    const vec3 coeffs4 = vec3(-450.5612460138105, -222.9483953226109, -603.1134948526307);
    const vec3 coeffs5 = vec3(414.3714147825587, 204.5005877644663, 521.3387680261235);
    const vec3 coeffs6 = vec3(-139.5436190113148, -66.14747768242114, -168.9654607398374);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 delta(float t) {

    const vec3 coeffs0 = vec3(0.00461618783580775, 0.1068261109305553, 0.157167328919833);
    const vec3 coeffs1 = vec3(5.943004901278719, 2.248524556669927, 9.831835756198224);
    const vec3 coeffs2 = vec3(-75.36856230528016, -11.19841454698138, -87.81503948863562);
    const vec3 coeffs3 = vec3(360.2235883972396, 73.06647490808331, 380.1385858849179);
    const vec3 coeffs4 = vec3(-707.9028270348266, -181.6014369657383, -784.6196569885366);
    const vec3 coeffs5 = vec3(607.5594161651022, 181.856847190589, 741.056374732676);
    const vec3 coeffs6 = vec3(-190.3443827281435, -64.40038834788498, -258.8755880550481);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 curl(float t) {

    const vec3 coeffs0 = vec3(0.08962728805479969, 0.07501538988258005, 0.1700493003222903);
    const vec3 coeffs1 = vec3(1.579841484801968, 4.304038143696945, 6.617955799079747);
    const vec3 coeffs2 = vec3(-33.661542465549, -30.35604905478976, -67.86371186278501);
    const vec3 coeffs3 = vec3(205.9860771167478, 143.7798302226145, 310.2853818776317);
    const vec3 coeffs4 = vec3(-448.1227929905509, -303.2633652447609, -633.8538752129085);
    const vec3 coeffs5 = vec3(412.4813162914828, 276.3950917234192, 582.9988926854078);
    const vec3 coeffs6 = vec3(-138.2618815373121, -90.90126447263594, -198.2548409862529);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 diff(float t) {

    const vec3 coeffs0 = vec3(0.001500700055958148, 0.09947227978773299, 0.2080469759483741);
    const vec3 coeffs1 = vec3(2.306541094150047, 4.113153938027294, 4.750598364401464);
    const vec3 coeffs2 = vec3(-16.1511254547237, -32.38213182796435, -41.29800818380711);
    const vec3 coeffs3 = vec3(104.6676203609791, 155.8218726731146, 190.3683631059077);
    const vec3 coeffs4 = vec3(-248.1733244180265, -327.2233603244062, -388.6682583867327);
    const vec3 coeffs5 = vec3(240.1900767521184, 299.8247681606206, 349.0577184027252);
    const vec3 coeffs6 = vec3(-82.78735897612485, -100.1692810712662, -114.4231016788617);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}

vec3 tarn(float t) {

    const vec3 coeffs0 = vec3(0.06531537855888914, 0.08647450335062601, 0.008577228247165891);
    const vec3 coeffs1 = vec3(1.990983085175937, 5.179194893467113, 3.430194706869979);
    const vec3 coeffs2 = vec3(-3.702333388139106, -46.57045750577331, -50.05133657737922);
    const vec3 coeffs3 = vec3(46.44163796638949, 210.9097713947284, 274.5217271116994);
    const vec3 coeffs4 = vec3(-139.5067846962796, -419.7026148508944, -593.2261237910436);
    const vec3 coeffs5 = vec3(143.9299670540178, 372.0829971861031, 554.3847437415247);
    const vec3 coeffs6 = vec3(-49.14680356107306, -121.9345341496654, -188.8680503338646);

    return coeffs0+t*(coeffs1+t*(coeffs2+t*(coeffs3+t*(coeffs4+t*(coeffs5+t*coeffs6)))));

}


varying float v_value;
uniform float addOffset;
uniform float scaleFactor;
uniform int colormap;

void main() {
    gl_FragColor.a = 1.0;
    float normalized_value = clamp(addOffset + scaleFactor * v_value, 0.0, 1.0);

    if (colormap == 0) {
        gl_FragColor.rgb = inferno(normalized_value);
    } else if (colormap == 1) {
        gl_FragColor.rgb = magma(normalized_value);
    } else if (colormap == 2) {
        gl_FragColor.rgb = plasma(normalized_value);
    } else if (colormap == 3) {
        gl_FragColor.rgb = viridis(normalized_value);
    } else if (colormap == 4) {
        gl_FragColor.rgb = turbo(normalized_value);
    } else if (colormap == 5) {
        gl_FragColor.rgb = hsluv(normalized_value);
    } else if (colormap == 6) {
        gl_FragColor.rgb = hpluv(normalized_value);
    } else if (colormap == 7) {
        gl_FragColor.rgb = cividis(normalized_value);
    } else if (colormap == 8) {
        gl_FragColor.rgb = Greys(normalized_value);
    } else if (colormap == 9) {
        gl_FragColor.rgb = Purples(normalized_value);
    } else if (colormap == 10) {
        gl_FragColor.rgb = Blues(normalized_value);
    } else if (colormap == 11) {
        gl_FragColor.rgb = Greens(normalized_value);
    } else if (colormap == 12) {
        gl_FragColor.rgb = Oranges(normalized_value);
    } else if (colormap == 13) {
        gl_FragColor.rgb = Reds(normalized_value);
    } else if (colormap == 14) {
        gl_FragColor.rgb = YlOrBr(normalized_value);
    } else if (colormap == 15) {
        gl_FragColor.rgb = YlOrRd(normalized_value);
    } else if (colormap == 16) {
        gl_FragColor.rgb = OrRd(normalized_value);
    } else if (colormap == 17) {
        gl_FragColor.rgb = PuRd(normalized_value);
    } else if (colormap == 18) {
        gl_FragColor.rgb = RdPu(normalized_value);
    } else if (colormap == 19) {
        gl_FragColor.rgb = BuPu(normalized_value);
    } else if (colormap == 20) {
        gl_FragColor.rgb = GnBu(normalized_value);
    } else if (colormap == 21) {
        gl_FragColor.rgb = PuBu(normalized_value);
    } else if (colormap == 22) {
        gl_FragColor.rgb = YlGnBu(normalized_value);
    } else if (colormap == 23) {
        gl_FragColor.rgb = PuBuGn(normalized_value);
    } else if (colormap == 24) {
        gl_FragColor.rgb = BuGn(normalized_value);
    } else if (colormap == 25) {
        gl_FragColor.rgb = YlGn(normalized_value);
    } else if (colormap == 26) {
        gl_FragColor.rgb = PiYG(normalized_value);
    } else if (colormap == 27) {
        gl_FragColor.rgb = PRGn(normalized_value);
    } else if (colormap == 28) {
        gl_FragColor.rgb = BrBG(normalized_value);
    } else if (colormap == 29) {
        gl_FragColor.rgb = PuOr(normalized_value);
    } else if (colormap == 30) {
        gl_FragColor.rgb = RdGy(normalized_value);
    } else if (colormap == 31) {
        gl_FragColor.rgb = RdBu(normalized_value);
    } else if (colormap == 32) {
        gl_FragColor.rgb = RdYlBu(normalized_value);
    } else if (colormap == 33) {
        gl_FragColor.rgb = RdYlGn(normalized_value);
    } else if (colormap == 34) {
        gl_FragColor.rgb = Spectral(normalized_value);
    } else if (colormap == 35) {
        gl_FragColor.rgb = coolwarm(normalized_value);
    } else if (colormap == 36) {
        gl_FragColor.rgb = bwr(normalized_value);
    } else if (colormap == 37) {
        gl_FragColor.rgb = seismic(normalized_value);
    } else if (colormap == 38) {
        gl_FragColor.rgb = thermal(normalized_value);
    } else if (colormap == 39) {
        gl_FragColor.rgb = haline(normalized_value);
    } else if (colormap == 40) {
        gl_FragColor.rgb = solar(normalized_value);
    } else if (colormap == 41) {
        gl_FragColor.rgb = ice(normalized_value);
    } else if (colormap == 42) {
        gl_FragColor.rgb = gray(normalized_value);
    } else if (colormap == 43) {
        gl_FragColor.rgb = deep(normalized_value);
    } else if (colormap == 44) {
        gl_FragColor.rgb = dense(normalized_value);
    } else if (colormap == 45) {
        gl_FragColor.rgb = algae(normalized_value);
    } else if (colormap == 46) {
        gl_FragColor.rgb = matter(normalized_value);
    } else if (colormap == 47) {
        gl_FragColor.rgb = turbid(normalized_value);
    } else if (colormap == 48) {
        gl_FragColor.rgb = speed(normalized_value);
    } else if (colormap == 49) {
        gl_FragColor.rgb = amp(normalized_value);
    } else if (colormap == 50) {
        gl_FragColor.rgb = tempo(normalized_value);
    } else if (colormap == 51) {
        gl_FragColor.rgb = rain(normalized_value);
    } else if (colormap == 52) {
        gl_FragColor.rgb = phase(normalized_value);
    } else if (colormap == 53) {
        gl_FragColor.rgb = balance(normalized_value);
    } else if (colormap == 54) {
        gl_FragColor.rgb = delta(normalized_value);
    } else if (colormap == 55) {
        gl_FragColor.rgb = curl(normalized_value);
    } else if (colormap == 56) {
        gl_FragColor.rgb = diff(normalized_value);
    } else if (colormap == 57) {
        gl_FragColor.rgb = tarn(normalized_value);
    }
}`;

const dataOnMeshVertexShader = `
    attribute float data_value;

    varying float v_value;

    void main() {
      v_value = data_value;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
    }
    `;

const dataOnScreenMeshVertexShader = `
    attribute float data_value;

    varying float v_value;

    void main() {
      v_value = data_value;
      gl_Position = vec4(position,1.0);
    }
    `;

export function makeColormapMaterial(
  colormap: TColorMap = "turbo",
  addOffset: 1.0 | 0.0 = 0.0,
  scaleFactor: -1.0 | 1.0 = 1.0
) {
  const material = new THREE.ShaderMaterial({
    uniforms: {
      addOffset: { value: addOffset },
      scaleFactor: { value: scaleFactor },
      colormap: { value: availableColormaps[colormap] },
    },

    vertexShader: dataOnMeshVertexShader,
    fragmentShader: colormapFragmentShader,
  });
  return material;
}

export function makeLutMaterial(
  colormap: TColorMap = "turbo",
  addOffset: 0 | 1,
  scaleFactor: 1 | -1
) {
  const material = new THREE.ShaderMaterial({
    uniforms: {
      addOffset: { value: addOffset },
      scaleFactor: { value: scaleFactor },
      colormap: { value: availableColormaps[colormap] },
    },

    vertexShader: dataOnScreenMeshVertexShader,
    fragmentShader: colormapFragmentShader,
  });
  return material;
}

export function makeLutGeometry() {
  const geometry = new THREE.PlaneGeometry(0.1, 1).translate(0.95, 0, 0);
  geometry.setAttribute(
    "data_value",
    new THREE.BufferAttribute(Float32Array.from([1, 1, 0, 0]), 1)
  );
  return geometry;
}
